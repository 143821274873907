html {
  @include fluid-type($min_width, $max_width, $min_font, $max_font);
  box-sizing: border-box;
}

*,
*:before,
:after {
  box-sizing: inherit;
}

body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  color: $black;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.hidden {
  opacity: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.cap {
  text-transform: capitalize;
}

.title {
  font-weight: 700;
  font-size: 90%;
  text-align: center;
  padding: 0;
}

.error-message-container {
  background: $light-coral;
  padding: 6px 24px;
  color: white;
  font-size: 54%;
  margin: 0 0 32px 0;
  border-radius: 16px;
  text-align: center;
  border: 2px solid $light-coral-soft;
}

#login-form {
  width: 300px;
  max-width: 300px;
  margin: 0 auto;
}

.loading-container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $black;
  z-index: 1;
  top: 0;
  left: 0;
  .loading-spinner {
    width: 100px;
    margin: 0 auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    svg {
      background-color: transparent !important;
    }
  }
}

.dismiss-btn {
  color: lighten($nickel, 5%);
}

.container {
  position: relative;
  width: 100vw;
  height: 75vh;
  #dashboard-container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: flex-start;
    width: inherit;
    height: inherit;
    transition: opacity 0.3s linear;
    #stream-edit-modal {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
      //box-shadow: 0 0 0 40px lighten($mint-cream, 2%);
      background: $black;
      opacity: 1;
      transition: opacity 0.2s linear;
      #stream-edit-form {
        position: relative;
        margin: auto;
        .dismiss-btn {
          position: absolute;
          display: inline-block;
          right: -14px;
          top: -50px;
          cursor: pointer;
          transition: all 0.1s ease-in;
          &:hover {
            transform: scale(1.2);
          }
        }
      }
    }
    .stream-item {
      position: relative;
      width: 90%;
      //box-shadow: 5px 5px 15px rgba($black, 0.5);
      border-radius: 10px;
      background: transparent;
      margin: 0 16px 16px;
      color: lighten($nickel, 28%);
      flex: 1 0 68%;
      img {
        width: 100%;
        max-width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }
      .stream-content {
        position: absolute;
        bottom: -40px;
        background-color: $black;
        text-align: center;
        width: 100%;
        height: 75%;
        padding: 0 0 18px 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        z-index: 1;
      }
    }
  }
}

.op-thumbnail-container {
  .op-thumbnail-wrapper {
    padding: 1.6em;
    .op-thumbnail-header {
      font-size: 1.7em;
    }
  }
}

.op-message-text {
  display: none !important;
}

.op-message-icon {
  .op-con {
    font-size: 28px !important;
    width: 28px !important;
    height: 28px !important;
    line-height: 28px !important;
  }
}

.op-live-badge-lowlatency {
  display: none !important;
}

.setting-holder.op-navigators.op-clear {
  visibility: hidden;
  pointer-events: none;
}

@media (min-width: 768px) {
  .container {
    #dashboard-container {
      flex-flow: row nowrap;
      align-items: flex-start;
      justify-content: center;
      .stream-item {
        width: 320px;
        max-width: 320px;
        height: inherit;
      }
    }
  }
}

@media (min-height: 900px) {
  .container {
    height: 58vh;
  }
}
