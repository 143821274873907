@font-face {
  font-family: "#{$icomoon-font-family}";
  src: url(#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?2060l)
      format("truetype"),
    url(#{$icomoon-font-path}/#{$icomoon-font-family}.woff?2060l) format("woff"),
    url(#{$icomoon-font-path}/#{$icomoon-font-family}.svg?2060l##{$icomoon-font-family})
      format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.icon {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "#{$icomoon-font-family}" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-error {
  &:before {
    content: $icon-error;
  }
}
.icon-warning {
  &:before {
    content: $icon-warning;
  }
}
.icon-album {
  &:before {
    content: $icon-album;
  }
}
.icon-fast_forward {
  &:before {
    content: $icon-fast_forward;
  }
}
.icon-fast_rewind {
  &:before {
    content: $icon-fast_rewind;
  }
}
.icon-games {
  &:before {
    content: $icon-games;
  }
}
.icon-loop {
  &:before {
    content: $icon-loop;
  }
}
.icon-pause {
  &:before {
    content: $icon-pause;
  }
}
.icon-pause_circle_filled {
  &:before {
    content: $icon-pause_circle_filled;
  }
}
.icon-play_arrow {
  &:before {
    content: $icon-play_arrow;
  }
}
.icon-play_circle_filled {
  &:before {
    content: $icon-play_circle_filled;
  }
}
.icon-playlist_add {
  &:before {
    content: $icon-playlist_add;
  }
}
.icon-skip_next {
  &:before {
    content: $icon-skip_next;
  }
}
.icon-skip_previous {
  &:before {
    content: $icon-skip_previous;
  }
}
.icon-stop {
  &:before {
    content: $icon-stop;
  }
}
.icon-volume_down {
  &:before {
    content: $icon-volume_down;
  }
}
.icon-volume_mute {
  &:before {
    content: $icon-volume_mute;
  }
}
.icon-volume_off {
  &:before {
    content: $icon-volume_off;
  }
}
.icon-volume_up {
  &:before {
    content: $icon-volume_up;
  }
}
.icon-playlist_play {
  &:before {
    content: $icon-playlist_play;
  }
}
.icon-subscriptions {
  &:before {
    content: $icon-subscriptions;
  }
}
.icon-playlist_add_check {
  &:before {
    content: $icon-playlist_add_check;
  }
}
.icon-queue_play_next {
  &:before {
    content: $icon-queue_play_next;
  }
}
.icon-remove_from_queue {
  &:before {
    content: $icon-remove_from_queue;
  }
}
.icon-branding_watermark {
  &:before {
    content: $icon-branding_watermark;
  }
}
.icon-control_camera {
  &:before {
    content: $icon-control_camera;
  }
}
.icon-chat {
  &:before {
    content: $icon-chat;
  }
}
.icon-comment {
  &:before {
    content: $icon-comment;
  }
}
.icon-email {
  &:before {
    content: $icon-email;
  }
}
.icon-vpn_key {
  &:before {
    content: $icon-vpn_key;
  }
}
.icon-mail_outline {
  &:before {
    content: $icon-mail_outline;
  }
}
.icon-add_box {
  &:before {
    content: $icon-add_box;
  }
}
.icon-add_circle {
  &:before {
    content: $icon-add_circle;
  }
}
.icon-clear {
  &:before {
    content: $icon-clear;
  }
}
.icon-create {
  &:before {
    content: $icon-create;
  }
}
.icon-flag {
  &:before {
    content: $icon-flag;
  }
}
.icon-forward {
  &:before {
    content: $icon-forward;
  }
}
.icon-remove_circle {
  &:before {
    content: $icon-remove_circle;
  }
}
.icon-file_copy {
  &:before {
    content: $icon-file_copy;
  }
}
.icon-how_to_reg {
  &:before {
    content: $icon-how_to_reg;
  }
}
.icon-border_color {
  &:before {
    content: $icon-border_color;
  }
}
.icon-insert_drive_file {
  &:before {
    content: $icon-insert_drive_file;
  }
}
.icon-file_download {
  &:before {
    content: $icon-file_download;
  }
}
.icon-file_upload {
  &:before {
    content: $icon-file_upload;
  }
}
.icon-folder {
  &:before {
    content: $icon-folder;
  }
}
.icon-keyboard_arrow_down {
  &:before {
    content: $icon-keyboard_arrow_down;
  }
}
.icon-keyboard_arrow_left {
  &:before {
    content: $icon-keyboard_arrow_left;
  }
}
.icon-keyboard_arrow_right {
  &:before {
    content: $icon-keyboard_arrow_right;
  }
}
.icon-keyboard_arrow_up {
  &:before {
    content: $icon-keyboard_arrow_up;
  }
}
.icon-keyboard_capslock {
  &:before {
    content: $icon-keyboard_capslock;
  }
}
.icon-security {
  &:before {
    content: $icon-security;
  }
}
.icon-looks_3 {
  &:before {
    content: $icon-looks_3;
  }
}
.icon-looks_4 {
  &:before {
    content: $icon-looks_4;
  }
}
.icon-looks_5 {
  &:before {
    content: $icon-looks_5;
  }
}
.icon-looks_6 {
  &:before {
    content: $icon-looks_6;
  }
}
.icon-looks_one {
  &:before {
    content: $icon-looks_one;
  }
}
.icon-looks_two {
  &:before {
    content: $icon-looks_two;
  }
}
.icon-arrow_drop_down {
  &:before {
    content: $icon-arrow_drop_down;
  }
}
.icon-arrow_drop_down_circle {
  &:before {
    content: $icon-arrow_drop_down_circle;
  }
}
.icon-arrow_drop_up {
  &:before {
    content: $icon-arrow_drop_up;
  }
}
.icon-cancel {
  &:before {
    content: $icon-cancel;
  }
}
.icon-check {
  &:before {
    content: $icon-check;
  }
}
.icon-fullscreen {
  &:before {
    content: $icon-fullscreen;
  }
}
.icon-fullscreen_exit {
  &:before {
    content: $icon-fullscreen_exit;
  }
}
.icon-menu {
  &:before {
    content: $icon-menu;
  }
}
.icon-keyboard_control {
  &:before {
    content: $icon-keyboard_control;
  }
}
.icon-more_vert {
  &:before {
    content: $icon-more_vert;
  }
}
.icon-unfold_less {
  &:before {
    content: $icon-unfold_less;
  }
}
.icon-unfold_more {
  &:before {
    content: $icon-unfold_more;
  }
}
.icon-first_page {
  &:before {
    content: $icon-first_page;
  }
}
.icon-last_page {
  &:before {
    content: $icon-last_page;
  }
}
.icon-arrow_left {
  &:before {
    content: $icon-arrow_left;
  }
}
.icon-arrow_right {
  &:before {
    content: $icon-arrow_right;
  }
}
.icon-arrow_back_ios {
  &:before {
    content: $icon-arrow_back_ios;
  }
}
.icon-arrow_forward_ios {
  &:before {
    content: $icon-arrow_forward_ios;
  }
}
.icon-sim_card_alert {
  &:before {
    content: $icon-sim_card_alert;
  }
}
.icon-sms_failed {
  &:before {
    content: $icon-sms_failed;
  }
}
.icon-ondemand_video {
  &:before {
    content: $icon-ondemand_video;
  }
}
.icon-priority_high {
  &:before {
    content: $icon-priority_high;
  }
}
.icon-person {
  &:before {
    content: $icon-person;
  }
}
.icon-person_add {
  &:before {
    content: $icon-person_add;
  }
}
.icon-share {
  &:before {
    content: $icon-share;
  }
}
.icon-check_box {
  &:before {
    content: $icon-check_box;
  }
}
.icon-check_box_outline_blank {
  &:before {
    content: $icon-check_box_outline_blank;
  }
}
.icon-radio_button_on {
  &:before {
    content: $icon-radio_button_on;
  }
}
.icon-account_box {
  &:before {
    content: $icon-account_box;
  }
}
.icon-account_circle {
  &:before {
    content: $icon-account_circle;
  }
}
.icon-delete {
  &:before {
    content: $icon-delete;
  }
}
.icon-home {
  &:before {
    content: $icon-home;
  }
}
.icon-https {
  &:before {
    content: $icon-https;
  }
}
.icon-info {
  &:before {
    content: $icon-info;
  }
}
.icon-label {
  &:before {
    content: $icon-label;
  }
}
.icon-note_add {
  &:before {
    content: $icon-note_add;
  }
}
.icon-perm_contact_calendar {
  &:before {
    content: $icon-perm_contact_calendar;
  }
}
.icon-settings {
  &:before {
    content: $icon-settings;
  }
}
.icon-verified_user {
  &:before {
    content: $icon-verified_user;
  }
}
.icon-http {
  &:before {
    content: $icon-http;
  }
}
.icon-toggle_off {
  &:before {
    content: $icon-toggle_off;
  }
}
.icon-toggle_on {
  &:before {
    content: $icon-toggle_on;
  }
}
.icon-double_arrow {
  &:before {
    content: $icon-double_arrow;
  }
}
.icon-privacy_tip {
  &:before {
    content: $icon-privacy_tip;
  }
}
.icon-contact_page {
  &:before {
    content: $icon-contact_page;
  }
}
