header {
  padding: 12px $padding_x 0;
  margin: 0 auto;
  text-align: center;
  .logo {
    width: 200px;
    display: inline-block;
    margin: 32px 0;
    svg {
      width: 100%;
    }
  }
}
